import React from "react"
import PropTypes from "prop-types"

class Info extends React.Component {
    render() {
        return (
            <img src={"/images/info.png"}/>
        );
    }
}

export default Info
